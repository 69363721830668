import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { Box } from '@mui/material'
import CheckboxList from 'components/pages/dis-v2/_components/CheckboxList'
import OtherOptions from 'components/pages/dis-v2/_components/OtherOptions'

const masterDateTimeTilePrimaryKeyColumns = [
    {
        title: 'Master DateTime',
        name: 'Master_DateTime',
        hide: false,
        selectableTypes: ['DATE', 'DATETIME'],
    },
    // {
    //     title: 'Master Text',
    //     name: 'Master_Text',
    //     hide: false,
    // },
]

const DEFAULT_PRIMARY_KEYS = ['Master_DateTime']

export default function MasterDateTimeForm(props) {
    const { filterData, onFilterDataChange } = props

    const { t } = useTranslation('common')

    const OTHER_OPTIONS = [
        {
            label: t('common:chart.enableEdit'),
            value: 'enableEdit',
        },
    ]

    const [primaryKeyList, setPrimaryKeyList] = useState(DEFAULT_PRIMARY_KEYS)
    const [checkedPrimaryKeys, setCheckedPrimaryKeys] = useState([])

    useEffect(() => {
        const validPrimaryKeys = filterData?.primaryKeyList?.filter(item => DEFAULT_PRIMARY_KEYS.includes(item))
        const validCheckedPrimaryKeys = filterData?.checkedPrimaryKeys?.filter(item => DEFAULT_PRIMARY_KEYS.includes(item))

        const isEmptyPrimaryKeyList = isEmpty(validPrimaryKeys)
        const isEmptyCheckedPrimaryKeys = isEmpty(validCheckedPrimaryKeys)
        if (!isEmptyPrimaryKeyList && !isEmptyCheckedPrimaryKeys) return

        if (isEmptyPrimaryKeyList) {
            onFilterDataChange('primaryKeyList', DEFAULT_PRIMARY_KEYS)
        }

        // if (isEmptyCheckedPrimaryKeys) {
        //     onFilterDataChange('checkedPrimaryKeys', DEFAULT_CHECKED_PRIMARY_KEYS)
        // }
    }, [])

    useEffect(() => {
        const validPrimaryKeys = filterData?.primaryKeyList?.filter(item => DEFAULT_PRIMARY_KEYS.includes(item))
        if (isEmpty(validPrimaryKeys)) {
            setPrimaryKeyList(DEFAULT_PRIMARY_KEYS)
            return
        }

        setPrimaryKeyList(filterData?.primaryKeyList)
    }, [filterData?.primaryKeyList])

    useEffect(() => {
        if (filterData?.checkedPrimaryKeys) {
            setCheckedPrimaryKeys(filterData?.checkedPrimaryKeys)
        }
    }, [filterData?.checkedPrimaryKeys])

    const handleSetPrimaryKey = (id) => {
        if (id === 'Master_DateTime') return
        if (primaryKeyList.includes(id)) {
            onFilterDataChange('primaryKeyList', primaryKeyList.filter((item) => item !== id))
        } else {
            onFilterDataChange('primaryKeyList', [...primaryKeyList, id])
        }
    }

    const handlePrimaryKeyCheck = (value) => () => {
        if (!Array.isArray(checkedPrimaryKeys)) return
        const currentIndex = checkedPrimaryKeys?.indexOf(value)
        const newChecked = [...checkedPrimaryKeys]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        onFilterDataChange('checkedPrimaryKeys', newChecked)
    }

    return (
        <div>
            <Box>
                <CheckboxList
                    hideScroll={true}
                    title={t('common:dis.selectFields')}
                    enableIcon
                    enableCheckbox={true}
                    onIconClick={handleSetPrimaryKey}
                    checkedIconList={primaryKeyList}
                    data={masterDateTimeTilePrimaryKeyColumns}
                    onItemClick={handlePrimaryKeyCheck}
                    checkedList={checkedPrimaryKeys}
                    getLabel={(option) => option.title}
                    getValue={(option) => option.name}
                />
            </Box>
            <Box>
                <OtherOptions
                    hideScroll={true}
                    optionList={OTHER_OPTIONS}
                    checkedOptions={filterData?.otherOptionsChecked}
                    onChangeOptions={(value) => onFilterDataChange('otherOptionsChecked', value)}
                />
            </Box>
        </div>
    )
}
